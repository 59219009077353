<template>
  <b-col>
    <b-row>
      <!-- modal - fund acount-->
      <b-modal id="fund-account" modal-class="modal-primary" title="Fund Account">
        <b-form>
          <b-form-group
            label="Amount (€)"
          >
            <b-form-input
              type="number"
              min="0" oninput="validity.valid||(value='');"
              v-model="fundAccountAmount"
              placeholder="Amount to fund your account"
            />
          </b-form-group>
        </b-form>
        <template #modal-footer>
          <b-button
            variant="primary"
            :disabled="!fundAccountAmount"
            @click="addMoney"
          >Fund</b-button>
        </template>
      </b-modal>
      <div class="col-lg-3">
        <b-card title="Balance">
          <b-row>
            <b-col>
              <b-form-group
                label="Account Balance"
                label-for="client-balance"
              >
                <div class="mt-1">
                  <h4>{{ balance }}</h4>
                    </div>
                  </b-form-group>
<!--                  <b-form-group-->
<!--                    label="Subscription Type"-->
<!--                    label-for="subscription-type"-->
<!--                  >-->
<!--                    <b-form-radio-group-->
<!--                      v-model="optionsLocal.subscriptionType"-->
<!--                      name="subscription-type"-->
<!--                      :options="subscriptionTypeOptions"-->
<!--                    />-->
<!--                  </b-form-group>-->
                  <b-button variant="primary" size="sm" class="mt-1" v-b-modal.fund-account>
                    <feather-icon icon="PlusIcon"/>Add money
                  </b-button>
                </b-col>
              </b-row>
        </b-card>
      </div>
      <div class="col-lg-5">
        <b-card title="Invoices">
            <b-row>
                <b-col sm="3"><b>Created</b></b-col>
                <b-col sm="3"><b>Due</b></b-col>
                <b-col sm="2"><b>Amount</b></b-col>
                <b-col sm="3"><b>Status</b></b-col>
                <b-col sm="1"></b-col>
            </b-row>
            <b-row v-for="inv in invoices" :key="inv.id">
                <b-col sm="3">{{ inv.invoice.DateString | moment('DD.MM.YYYY') }}</b-col>
                <b-col sm="3">{{ inv.invoice.DueDateString | moment('DD.MM.YYYY') }}</b-col>
                <b-col sm="2">{{ inv.invoice.Total }} {{ inv.invoice.CurrencyCode }}</b-col>
                <b-col sm="3">{{ inv.invoice.Status }}</b-col>
                <b-col sm="1">
                    <a :href="inv.url" target="_blank"><feather-icon size="16" icon="LinkIcon" class="cursor-pointer"/></a>
<!--                    <feather-icon size="16" icon="TrashIcon" class="cursor-pointer"/>-->
                </b-col>
            </b-row>
        </b-card>
      </div>
      <div class="col-lg-4">
        <b-card title="Profile Information">
              <!-- form -->
              <validation-observer ref="profileValidation">
                <b-form class="mt-2"
                        @submit.prevent>
                  <b-row>
                    <b-col sm="12">
                      <b-form-group
                        label="Name"
                        label-for="account-name"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Name"
                          rules="required"
                        >
                          <b-form-input
                            v-model="optionsLocal.fullName"
                            name="account-name"
                            placeholder="Name"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12">
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <b-form-group
                          label="E-mail"
                          label-for="account-e-mail"
                        >
                          <b-form-input
                            v-model="optionsLocal.email"
                            name="account-e-mail"
                            placeholder="Email"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>

                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col sm="12">
                      <b-form-group
                        label="Company / Client"
                        label-for="account-company"
                      >
                        <b-form-input
                          v-model="optionsLocal.company"
                          readonly
                          name="company"
                          placeholder="Company name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="float-right"
                        type="submit"
                        @click="validationForm"
                      >
                        Save changes
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>

              </validation-observer>
        </b-card>
      </div>
    </b-row>

    <b-row>
      <div class="col-lg-12">
        <b-card title="Change Password">
          <!-- form -->
          <b-form>
            <b-row>
              <!-- new password -->
              <b-col md="6">
                <b-form-group
                  label-for="account-new-password"
                  label="New Password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-new-password"
                      v-model="newPasswordValue"
                      :type="passwordFieldTypeNew"
                      name="new-password"
                      placeholder="New Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!--/ new password -->

              <!-- retype password -->
              <b-col md="6">
                <b-form-group
                  label-for="account-retype-new-password"
                  label="Retype New Password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-retype-new-password"
                      v-model="RetypePassword"
                      :type="passwordFieldTypeRetype"
                      name="retype-password"
                      placeholder="New Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!--/ retype password -->

              <!-- buttons -->
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1 mr-1"
                  @click="changePassword"
                >
                  Save changes
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  class="mt-1"
                >
                  Reset
                </b-button>
              </b-col>
              <!--/ buttons -->
            </b-row>
          </b-form>
        </b-card>
      </div>
    </b-row>
  </b-col>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
  BButton, BForm, BFormGroup, BFormRadioGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import axios from '@/auth/http'

export default {
  name: 'Profile',
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      optionsLocal: {
        fullName: '',
        email: '',
        company: '',
        accountBalance: '',
        subscriptionType: 'Monthly Fee'
      },
      depositInfo: {
        'payment type': 'SEPA',
        'company name': 'Aphex Media Ltd',
        IBAN: 'GB82MOOW00993582957745',
        'BIC SWIFT': 'MOOWGB22XXX'
      },
      profileFile: null,
      subscriptionTypeOptions: ['Monthly Fee', 'Percentage of transactions'],
      accounts: [],
      fundAccountAmount: null,
      editedAccount: {},
      invoices: [],
    }
  },
  computed: {
    moment () {
      return moment
    },
    balance() {
      if (this.user.clients[0].customData.ppc && this.user.clients[0].customData.ppc.subAccount.currencyBalances.EUR.balance.available) {
        return `${parseInt(this.user.clients[0].customData.ppc.subAccount.currencyBalances.EUR.balance.available, 10) / 100} EUR`
      }
      return '/'
    },
    ...mapGetters({
      user: 'userInfo'
    }),
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.fetchAccounts()
    this.setUpInitUserData()
    this.fetchInvoices()
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    async setUpInitUserData() {
      await this.$store.dispatch('fetchUser')
      this.optionsLocal = {
        fullName: this.user.name,
        email: this.user.email,
        company: this.user.clients[0].name,
        accountBalance: this.user.clients[0].fullBalance
      }
    },
    validationForm() {
      this.$refs.profileValidation.validate().then(success => {
        if (success) {
          this.$store.dispatch('updateUser', {
            name: this.optionsLocal.fullName,
            email: this.optionsLocal.email,
          }).then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Data Updated',
                icon: 'CheckIcon',
                variant: 'success',
              },
            }, {
              position: 'top-center',
            })
            this.$router.push({ name: 'profile' })
          }).catch(error => {
            let msg = error.message
            if (error.response) {
              msg = error.response.data.msg
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: msg,
                icon: 'InfoIcon',
                variant: 'danger',
              },
            }, {
              position: 'top-center',
            })
          })
        }
      })
    },
    async fetchInvoices () {
      try {
        const invoices = await this.$store.dispatch('fetchInvoices')
        this.invoices = invoices.data
      } catch (e) {}
    },
    fetchAccounts () {
      this.$store.dispatch('fetchAccounts')
        .then(() => {
          this.accounts = this.$store.state.accountsStore.accounts
        })
        .catch(error => {
          let msg = error.message
          if (error.response) {
            msg = error.response.data.msg
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: msg,
              icon: 'InfoIcon',
              variant: 'danger',
            },
          }, {
            position: 'top-center',
          })
        })
    },
    addMoney () {
      this.$store.dispatch('fundAccount', { amount: this.fundAccountAmount })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice Created',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
          this.$bvModal.hide('fund-account')
          this.fetchInvoices()
        })
        .catch(error => {
          let msg = error.message
          if (error.response) {
            msg = error.response.data.msg
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: msg,
              icon: 'InfoIcon',
              variant: 'danger',
            }
          })
        })
    },
    async changePassword () {
      if (this.newPasswordValue.length < 6 || this.newPasswordValue !== this.RetypePassword) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Passwords must match and need to be at least 6 characters long',
            icon: 'InfoIcon',
            variant: 'danger',
          }
        })
        return
      }
      await axios.patch(`/users/change-password`, {
        password: this.newPasswordValue,
        retype: this.RetypePassword
      })
      this.newPasswordValue = ''
      this.RetypePassword = ''
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Passwords changed successfully',
          icon: 'InfoIcon',
          variant: 'success',
        }
      })

    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
